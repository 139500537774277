<template>
    <div>
        <div
            id="partner-hero"
            class="my-5 py-20 overflow-hidden shadow-lg rounded-2xl hero"
            style="
            "
        >
            <div class="lg:px-20 p-5 text-left text-white">
                <h1 class="font-bold text-4xl">Be Partner of GenBI IPB</h1>
                <p class="py-8 lg:w-1/2 lg:leading-8 text-justify">
                    GenBI IPB aktif dalam melakukan berbagai kegiatan sosial
                    mengenai pendidikan, kesehatan, dan lingkungan. Kolaborasi
                    dengan GenBI IPB adalah kesempatan yang bagus untuk
                    memperluas dampak positif dari kegiatan anda. Kami selalu
                    terbuka terhadap peluang kolaborasi yang menarik dan
                    berdampak baik bagi masyarakat.
                </p>
                <button
                    @click="goToForm"
                    class="rounded-full px-5 py-2 bg-blue-500 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-10 text-white mr-5"
                >
                    Mulai Kolaborasi
                </button>
            </div>
        </div>

        <div class="mt-24">
            <h2 class="font-bold text-4xl " :style="{ color: '#0D528D' }">
                Alur Kolaborasi
            </h2>
            <div
                class="grid grid-flow-col grid-rows-5 grid-cols-1 md:grid-rows-1 md:grid-cols-5 gap-16 mt-8"
            >
                <div v-for="flow in flows" :key="flow.title">
                    <img :src="flow.img" alt="" style="max-width:100%" />
                    <p class="font-bold text-xl mt-4">{{ flow.title }}</p>
                    <p class="mt-4">{{ flow.description }}</p>
                </div>
            </div>
        </div>
        <div class="mt-36">
            <h2 class="font-bold text-4xl mb-8" :style="{ color: '#0D528D' }">
                Bidang Kolaborasi
            </h2>
            <div
                class=" grid grid-flow-cols grid-cols-1 grid-rows-5 md:grid-cols-5 md:grid-rows-1 gap-4"
                style="width: 60%; margin:auto;"
            >
                <div class="flex-1" v-for="item in options" :key="item.title">
                    <button
                        v-if="collab.title == item.title"
                        class="py-2 px-3 rounded-md text-white"
                        style="width: 100%; background-color: #3898EC"
                        @click="currentCollab(item.title)"
                    >
                        <!-- Edukasi -->
                        {{ item.title }}
                    </button>
                    <button
                        v-else
                        class="py-2 px-3 rounded-md"
                        style="width: 100%; background-color: #DBEEFF; color: #9CC6EB"
                        @click="currentCollab(item.title)"
                    >
                        <!-- Edukasi -->
                        {{ item.title }}
                    </button>
                </div>
            </div>
            <transition slot="content" name="component-fade-x" mode="out-in">
                <div
                    class="flex flex-col md:flex-row space-x-4 mt-6"
                    style="align-items:center; min-height:450px"
                    :key="collab.title"
                >
                    <div class="flex-1 mr-1">
                        <img :src="collab.img" alt="" style="max-width: 100%" />
                    </div>
                    <div class="flex-1 text-left">
                        <p class="font-bold text-3xl">{{ collab.header }}</p>
                        <p class="mt-4">{{ collab.description }}</p>
                    </div>
                </div>
            </transition>
            <div
                class="my-24 bg-ready content-center overflow-hidden rounded-2xl"
            >
                <h2
                    class="lg:px-48 lg:pt-20 pt-16 pb-12 font-bold text-3xl"
                    :style="{ color: '#0D528D' }"
                >
                    Mari berdampak bersama GenBI IPB demi Indonesia yang lebih
                    baik
                </h2>
                <button
                    @click="goToForm"
                    class="rounded-full px-5 py-2 bg-blue-500 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-10 text-white"
                >
                    Mulai Kolaborasi
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            options: [
                {
                    title: 'Edukasi',
                },
                {
                    title: 'Kesehatan',
                },
                {
                    title: 'Lingkungan',
                },
                {
                    title: 'Social Media',
                },
                {
                    title: 'Lainnya',
                },
            ],
            flows: [
                {
                    img: require('@/assets/icons/register.png'),
                    title: '1. Pengisian Form',
                    description: 'Pengajuan proposal dan informasi lainnya',
                },
                {
                    img: require('@/assets/icons/review.png'),
                    title: '2. Review Proposal',
                    description:
                        'GenBI IPB mengkaji proposal dan melakukan dialog bila diperlukan',
                },
                {
                    img: require('@/assets/icons/mou.png'),
                    title: '3. Pengusulan MoU',
                    description:
                        'GenBI IPB mengusulkan MoU untuk disetujui kedua belah pihak',
                },
                {
                    img: require('@/assets/icons/agree.png'),
                    title: '4. Persetujuan MoU',
                    description: 'MoU ditandatangani kedua belah pihak',
                },
                {
                    img: require('@/assets/icons/execute.png'),
                    title: '5. Pelaksanaan',
                    description: 'Kegiatan dilaksanakan sesuai dengan MoU',
                },
            ],
            selectedCollab: '',
            collab: {
                image: '',
                header: '',
                description: '',
            },
            collabs: [
                {
                    title: 'Edukasi',
                    header: 'Partner di Edukasi',
                    description:
                        'GenBI IPB sangat mendukung upaya memajukan pendidikan Indonesia terutama edukasi dalam bidang keuangan. Kolaborasi bersama kami dapat dilakukan dengan membuat berbagai kegiatan edukasi seperti sharing session, kelas publik, seminar, webinar, artikel, infografis, online course, dan bentuk edukasi lainnya.',
                    img: require('@/assets/partner/edukasi.png'),
                },
                {
                    title: 'Kesehatan',
                    header: 'Partner di Kesehatan',
                    description:
                        'GenBI IPB membuka kesempatan kolaborasi untuk meningkatkan sikap peduli kesehatan agar dapat meningkatkan kualitas hidup masyarakat. Kolaborasi bisa berbentuk edukasi kesehatan, kegiatan donor darah, dan kegiatan menarik lainnya.',
                    img: require('@/assets/partner/kesehatan.png'),
                },
                {
                    title: 'Lingkungan',
                    header: 'Partner di Lingkungan',
                    description:
                        'GenBI IPB menyadari masalah-masalah yang ada di lingkungan sekitar kita dan aktif membantu meningkatkan kesadaran masyarakat untuk menjaga kebersihan dan kelestarian lingkungan. Kolaborasi bisa berbentuk aksi sosial, webinar pencerdasan, artikel, kampanye isu lingkungan, dan berbagai bentuk kolaborasi lainnya.',
                    img: require('@/assets/partner/lingkungan.png'),
                },
                {
                    title: 'Social Media',
                    header: 'Partner di Konten Sosial Media',
                    description:
                        'Kolaborasi untuk membuat konten media sosial seperti post di Instagram, podcast, infografis, live Instagram, dan konten lainnya. Konten bisa mencakup banyak sekali topik, mulai dari isu lingkungan, kesehatan, keuangan, isu-isu terkini, dan lain sebagainya. Hasil kolaborasi akan diunggah di media sosial GenBI IPB dengan mencantumkan nama semua pihak yang berkontribusi.',
                    img: require('@/assets/partner/sosmed.png'),
                },
                {
                    title: 'Lainnya',
                    header: 'Partner di Bidang Lainnya...',
                    description:
                        'Kami terbuka untuk kolaborasi dalam bentuk lainnya. Kalau kamu memiliki ide yang berbeda dengan bentuk kolaborasi yang telah diuraikan sebelumnya kamu tetap bisa berkolaborasi dengan kami!',
                    img: require('@/assets/partner/lainnya.png'),
                },
            ],
        }
    },
    methods: {
        goToForm() {
            // window.location.href =
            //     'https://docs.google.com/forms/d/e/1FAIpQLSe97tSgVEGQaE8L93BGa6IvQS3twG0qFmAtRrRxDP8MMpyRuQ/viewform'
            window.open(
                'https://docs.google.com/forms/d/e/1FAIpQLSe97tSgVEGQaE8L93BGa6IvQS3twG0qFmAtRrRxDP8MMpyRuQ/viewform',
                '_blank'
            )
        },
        currentCollab(title) {
            this.selectedCollab = title
            let select = this.collabs.find(element => {
                return element.title == title
            })
            console.log(select)
            this.collab = {
                ...select,
            }
            console.log(this.collab)
        },
    },
    metaInfo: {
        title: 'GenBI IPB',
        titleTemplate: `%s - Jadi partner GenBI IPB`,
        htmlAttrs: {
            lang: 'id',
        },
        meta: [
            { charset: 'utf-8' },
            {
                name: 'description',
                content:
                    'GenBI IPB aktif dalam melakukan berbagai kegiatan sosial mengenai pendidikan, kesehatan, dan lingkungan.',
            },
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
            },
        ],
    },
    mounted() {
        window.scroll(0, 0)

        this.currentCollab('Edukasi')
    },
}
</script>

<style>
.component-fade-y-enter-active,
.component-fade-y-leave-active {
    transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.component-fade-x-enter-active,
.component-fade-x-leave-active {
    transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.component-fade-enter-active,
.component-fade-leave-active {
    transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.component-fade-enter,
.component-fade-leave-to {
    opacity: 0;
}
.component-fade-x-enter,
.component-fade-x-leave-to {
    opacity: 0;
    transform: translateX(50px);
}
.component-fade-y-enter,
.component-fade-y-leave-to {
    opacity: 0;
    transform: translateY(20px);
}

.bg-ready {
    background-image: url('https://lh3.googleusercontent.com/4_sp0gKesvznH7_sr6_uWRL6x9_JQmFJAY1jBMNj4frqGbLSYciAV0v7cFLiSvs3502YXhJQjtI2HqhtUpVVYNwvmveDbZ4T8aMdTTUHMIeXN3ZN8B6FZ7ib_KCZIQxvRVoDLApd6_1DvlvCql5LWfU_EIFJa8lLFKocLgQwhEYjojr_ADldTqRNLfZq8JSG4tHuA7ypphS_Qx4WAPlY12ZVH7FYDkw_Da5dmTYv4PpNBjrUUufqAaWVCovKu1TKiR0aAHiWo34rZkTV0qr88MRXqYZ2o0FHDfLf30qBo9ZmSgj3UGPboELV4_auVEEZz1v1YHdlNR97S3BOmVSoDtm5QP7kS2aZIblwMi9Kl9P-yVSShqGQxICzh5gyjuNQBH1oxEPNXIFBBIx2F65yHcI_i6MolkelbpWsZhGOpwNEv9PUfhuR2DbIErEE52WOgNIMxZPG2oAa0BZZlyCoSQ1DR3Wm56RVGjyvg14QuvW1NsBf83IrI8_KyG2j6AmbQfigqdk8BUiUjLAQ-D-5xkIzfnU1I3NNmpDiFFHiLSK9_Ii5Z7Rr3xmQDJfHEXZlQ9Vcpf2iOBw99LlE4TH6iBhWrVJCWzx1N-jZEN82AbbYQU-_DKz-0FIwenOBH4gneqtw06sc6TnHcy8yHvpay3AoelzlbhPChsAzFG6uCC-QkblKnIjWFm9ue0PB5z_QqDiXJvVMl-4GZ8-W169JCGr9=w2400');
    background-size: 82.5rem;
    min-height: 320px;
    background-position: center;
    background-repeat: no-repeat;
}
#partner-hero {
    background-image: linear-gradient(
            to left,
            rgba(255, 255, 255, 0),
            rgba(0, 0, 0, 0.8)
        ),
        url('https://lh3.googleusercontent.com/NWkk7peF6swEwYMH2RSu-eBTf44aNkV7Z65jBXeM9edgryy6jZuWM3E6N-jpVo2JjaxhUq8m6XQo0xyLHNWrSFZQ2_jbPXU8pYqho4vbaJrVO_sixKHjJWJckPOHU9VOG2iUXZ1pXw=w2400');
}
</style>
